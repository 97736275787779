<template>
  <b-card>
    <div class="mb-3">
      <div class="text-black text-2xl font-semibold">
        Ubah Peserta
      </div>
    </div>
    <b-row>
      <b-col>
        <b-form-group
          id="input-group-1"
          label-for="input-1"
          class="mt-2"
        >
          <div class="w-[100%] md:w-[40%]">
            <b-form-group
              id="fieldset-1"
              label="Email"
              label-class="font-medium text-7 text-black"
              label-for="email"
            >
              <v-select
                id="email"
                v-model="email"
                taggable
                :options="state.optionEmail"
                label="email"
                placeholder="Pilih email peserta"
                :reduce="email => email"
                @input="handleInputEmail"
                @search="debounceSearchEmail"
              >
                <template #no-options>
                  {{ "" }}
                </template>

              </v-select>
            </b-form-group>
            <div class="mb-1 mt-2">

              <b-form-group
                id="fieldset-1"
                label="Nama Lengkap"
                label-class="font-medium text-7 text-black"
                label-for="name_participant"
              >

                <b-form-input
                  id="name_participant"
                  v-model="state.form.name"
                  type="text"
                  placeholder="Masukan nama peserta"
                  required
                />
              </b-form-group>
            </div>
            <div class="mb-1">

              <b-form-group
                id="fieldset-1"
                label="No Hp"
                label-class="font-medium text-7 text-black"
                label-for="handphone"
              >

                <b-input-group prepend="+62">
                  <b-form-input
                    id="handphone"
                    v-model="state.form.handphone"
                    type="number"
                    placeholder="8xxxxxxxxxxx"
                    required
                    @keypress="isNumber($event)"
                    @input="debounceWhatsApp"
                  />

                </b-input-group>
              </b-form-group>
            </div>
            <div class="mb-1">
              <b-form-group
                id="fieldset-1"
                label="Kota"
                label-class="font-medium text-7 text-black"
                label-for="city"
              >

                <v-select
                  id="city"
                  v-model="state.form.city"
                  :options="state.optionCity"
                  label="city"
                  placeholder="Pilih kota asal peserta"
                  @search="debounceSearchCity"
                >
                  <template #no-options>
                    {{ "" }}
                  </template>
                </v-select>
              </b-form-group>
            </div>
            <div>
              <div class="font-semibold mb-1 text-black">
                Produk Yang Terdaftar
              </div>
              <div class="d-flex container-img">
                <div
                  class="w-[3rem] h-[3rem] "
                  :class="state.form.product.komship ? 'bg-icon' : 'opacity'"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/logo/Product-Komship.svg"
                    alt="logo-komship"
                    class="mb-[-6px]"
                  >
                </div>
                <div
                  class="w-[3rem] h-[3rem]"
                  :class="state.form.product.komtim ? 'bg-icon' : 'opacity'"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/logo/logo_komtim.svg"
                    alt="logo-komtim"
                  >
                </div>
                <div
                  class="w-[3rem] h-[3rem] "
                  :class="state.form.product.kompack ? 'bg-icon' : 'opacity' "
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/svg/logo_kompack.svg"
                    alt="logo-kompack"
                  >
                </div>
                <div
                  class="w-[3rem] h-[3rem]"
                  :class="state.form.product.komcard ? 'bg-icon' : 'opacity' "
                >

                  <img
                    src="https://storage.googleapis.com/komerce/assets/logo/Product-Komcards.svg"
                    alt="logo-komcard"
                  >
                </div>

                <div
                  class="w-[3rem] h-[3rem]"
                  :class="state.form.product.boostr ? 'bg-icon' : 'opacity' "
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/LP-Komerce/komplace.svg"
                    alt="logo-boostr"
                    class="h-6"
                  >
                </div>
                <div
                  class="w-[3rem] h-[3rem] "
                  :class="state.form.product.pendamping_umkm ? 'bg-icon' : 'opacity'"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/logo/Product-Pendamping%20UMKM.svg"
                    alt="logo-p-umkm"
                  >
                </div>
              </div>
              <p
                class="mt-1"
              >
                Terdaftar di {{ state.form.email.product? (Object.values(state.form.email.product).filter(value => value === true).length) : (Object.values(state.form.product).filter(value => value === true).length) }} Product
              </p>
            </div>
          </div>
          <div
            class="d-flex justify-end "
            style="gap: 1rem;"
          >
            <b-button
              variant="outline-primary"
              @click="handleBack"
            >
              Kembali
            </b-button>

            <b-button
              v-b-modal.modal-add-event
              :disabled="state.haseEditParticipant? state.disableSubmit : !state.haseEditParticipant"
              variant="primary"
              type="submit"
              @click="handleSubmit"
            >
              Simpan
            </b-button>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>

</template>

<script>
import {
  reactive, onMounted, ref, watchEffect, onBeforeMount,
} from '@vue/composition-api'
import { newAxiosIns, komshipAxiosIns } from '@/libs/axios'
import { useRouter } from '@/@core/utils/utils'
import router from '@/router'
import vSelect from 'vue-select'
import { isNumber } from '@/libs/helpers'
import { toastError, toastSuccess } from '../../config'

export default {
  components: {
    vSelect,
  },
  setup() {
    const email = ref()
    const route = useRouter()
    const state = reactive({
      haseEditParticipant: false,
      disableSubmit: true,
      emailChanged: false,
      participant: {},
      data: {},
      form: {
        email: '',
        name: '',
        handphone: '',
        city: '',
        whatsapp: false,
        product: {
          kompack: false,
          komship: false,
          komtim: false,
          komcard: false,
          boostr: false,
          pendamping_umkm: false,
        },
      },
      optionEmail: [],
      optionCity: [],
      optionsEmail: [],
      debounceEmail: null,
      debounceCity: null,
      debounceWA: null,
    })

    function checkWA(number) {
      const url = '/v1/check-wa'
      komshipAxiosIns
        .post(url, { phone_no: number })
        .then(res => {
          const { data } = res.data
          if (data === 'valid') {
            state.form.whatsapp = true
          } else {
            state.form.whatsapp = false
          }
        })
        .catch(err => {
          state.form.whatsapp = false
          // eslint-disable-next-line no-console
          console.log(err)
        })
    }

    function getDataParticipant() {
      const participants = route.route.value.params.participant
      state.participant = participants
      email.value = participants.email
      state.form.name = participants.name
      state.form.handphone = participants.phone
      state.form.whatsapp = participants.whatsapp
      state.form.city = participants.city
      state.form.product.kompack = participants.product.kompack
      state.form.product.komship = participants.product.komship
      state.form.product.komtim = participants.product.komtim
      state.form.product.komcard = participants.product.komcard
      state.form.product.boostr = participants.product.boostr
      state.form.product.pendamping_umkm = participants.product.pendamping_umkm
      checkWA(participants.phone)
    }

    function debounceWhatsApp(number) {
      if (state.debounceWA) {
        clearTimeout(state.debounceWA)
      }
      state.debounceWA = setTimeout(() => {
        checkWA(number)
      }, 1000)
    }
    function handleInputEmail(value) {
      const data = state.optionsEmail.filter(item => item.email === value)
      if (data.length) {
        let noHp = data[0].no_hp
        if (noHp.charAt(0) === '0') {
          noHp = noHp.slice(1)
        }
        checkWA(noHp)
        state.form.name = data[0].full_name
        state.form.handphone = noHp
        state.form.product.komcard = data[0].komcard
        state.form.product.boostr = data[0].komplace
        state.form.product.komship = data[0].komship
        state.form.product.komtim = data[0].komtim
        state.form.product.kompack = data[0].kompack
        state.form.product.pendamping_umkm = data[0].pendamping_umkm
      } else {
        state.form.product.komcard = false
        state.form.product.boostr = false
        state.form.product.komship = false
        state.form.product.komtim = false
        state.form.product.kompack = false
        state.form.product.pendamping_umkm = false
      }
    }
    function getEmail(value) {
      if (value.length < 3) {
        return
      }
      const url = '/komclass/api/v2/users/option/email'
      newAxiosIns
        .get(url, {
          params: { email: value },
        })
        .then(res => {
          const { data } = res.data
          if (data === null) {
            state.form.product.komcard = false
            state.form.product.boostr = false
            state.form.product.komship = false
            state.form.product.komtim = false
            state.form.product.kompack = false
            state.form.product.pendamping_umkm = false
          }
          state.optionsEmail = data
          state.optionEmail = data.map(item => item.email)
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    }
    function debounceSearchEmail(search) {
      if (state.debounceEmail) {
        clearTimeout(state.debounceEmail)
      }
      state.debounceEmail = setTimeout(() => {
        getEmail(search)
      }, 1000)
    }

    async function searchCity(search) {
      if (search.length < 3) {
        return
      }
      const url = '/komclass/api/v1/event/option/city'
      await newAxiosIns
        .get(url, { params: { keyword: search } })
        .then(res => {
          const { data } = res.data
          state.optionCity = data.map(item => item.name)
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err, 'error')
        })
    }

    function debounceSearchCity(search) {
      if (state.debounceCity) {
        clearTimeout(state.debounceCity)
      }
      state.debounceCity = setTimeout(() => {
        searchCity(search)
      }, 2000)
    }

    function handleSubmit() {
      state.data = {
        event_id: parseInt(route.route.value.query.idEvent, 10),
        id: parseInt(route.route.value.params.participant.id, 10),
        email: email.value,
        name: state.form.name,
        phone: parseInt(state.form.handphone, 10),
        whatsapp: state.form.whatsapp,
        city: state.form.city,
        product: {
          kompack: state.form.product.kompack,
          komship: state.form.product.komship,
          komtim: state.form.product.komtim,
          komcard: state.form.product.komcard,
          boostr: state.form.product.boostr,
          pendamping_umkm: state.form.product.pendamping_umkm,
        },
      }
      const url = '/komclass/api/v1/event/attendance/upsert'
      newAxiosIns.post(url, state.data)
        .then(res => {
          const { data } = res.data
          toastSuccess('Berhasil mengubah peserta')
          router.back()
        })
        .catch(err => {
          toastError('Ada kesalahan saat mengubah peserta')
          router.back()
        })
    }

    function handleBack() {
      router.back()
    }

    const checkEmailChanged = () => {
      state.emailChanged = email.value !== state.participant.email
    }

    const checkDataChanged = () => {
      state.haseEditParticipant = email.value !== state.participant.email
                || state.form.name !== state.participant.name
                || parseInt(state.form.handphone, 10) !== parseInt(state.participant.phone, 10)
                || state.form.city !== state.participant.city
    }

    const isFormValid = () => {
      state.disableSubmit = state.form.name.length < 3 || !state.form.city || !email.value || !state.form.whatsapp || state.form.handphone.length === 0
    }

    watchEffect(() => {
      checkDataChanged()
      isFormValid()
      checkEmailChanged()
    })
    onBeforeMount(() => {
      getDataParticipant()
    })

    return {
      debounceSearchEmail,
      komshipAxiosIns,
      getEmail,
      checkWA,
      debounceSearchCity,
      searchCity,
      handleSubmit,
      handleBack,
      isNumber,
      debounceWhatsApp,
      email,
      handleInputEmail,
      state,
    }
  },

}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
.container-img {
  gap: 3px;
}
.opacity {
  opacity: 0.2;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 2px;
}
.bg-icon {
  background-color: antiquewhite;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 2px;
}
</style>
